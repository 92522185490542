export default {
  BUTTON_PRIMARY_CLASS: 'cm_button button-primary',
  BUTTON_SECONDARY_CLASS: 'cm_button button-secondary',

  SEARCH_BOX_CLASS: 'live-search-form form-fields-inline',
  SEARCH_BOX_INPUT_CLASS: 'form-field-input live-search-form-field',
  SEARCH_BOX_BUTTON_CLASS: 'live-search-button',
  SEARCH_BOX_FORM_CLASS: 'form-field no-label',
  SEARCH_BOX_PLACEHOLDER: 'What are you looking for?',

  MAX_PRICE: 'Max',
  MIN_PRICE: 'Min',

  SORT_BY: 'Sort by',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  YOU_MAY_ALSO_LIKE: 'You may also like',
  RELATED_PRODUCT_TITLE_CLASS: 'product-section--title product-recommendations--title',
  RELATED_PRODUCT_LIST_CLASS: 'product-section--content product-row',

  PRODUCT_REPEATER_CLASS: 'productgrid--items products-per-row-4',

  GARAGE_SIZE:
    '<span class="garage-size site-header-cart--count visible" key="garage-size" data-header-cart-count="{{size}}"></span>',
};
