
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return !this.isTitle ? _createElement('li', {
                    'className': 'fitment-table-item',
                    'key': '5'
                }, _createElement('span', { 'className': 'fitment-table-vehicle' }, '\n        ', this.fields.filter(f => f !== 'Notes').map(f => this[f]).join(' '), '\n      '), _createElement('br', {}), this.Notes ? _createElement('span', {
                    'className': 'fitment-table-notes',
                    'key': '237'
                }, _createElement('span', { 'className': 'fitment-table-notes-label' }, 'Notes:'), ' ', this.Notes) : null) : null;
            }, { count: undefined })];
    }
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'cm_fitment-table fitment-table-container',
        'key': '0'
    }, _createElement.apply(this, [
        'ul',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null;
}
        export const componentNames = []